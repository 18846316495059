.footer {
	text-align: center;
    background-color: @themeColor1;
    color: @whiteColor;
    ul {
    	list-style-type: none;
    	margin-top: 58px;
    	margin-bottom: 14px;
		// Small, Medium, Large devices
        @media (min-width: @screen-sm-min) {
    		margin-bottom: 34px;
        }
    	> li {
    		display: block;
    		padding: 0 17px;
			// Small devices
            @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
				padding: 0 8px;
            }
			// Small, Medium, Large devices
            @media (min-width: @screen-sm-min) {
    			display: inline-block;
            }
    	}
    }
    a {
    	color: @themeColor2;
    	img.footer-partner {
    		margin-left: 5px;
    	}
    }
    img.footer-partner {
    	display: block;
    	float: none;
    	margin: 10px auto;
		// Small, Medium, Large devices
        @media (min-width: @screen-sm-min) {
    		float: left;
    		margin: 0;
	    	+ img.footer-partner {
	    		margin-left: 5px;
	    	}
        }
    }
    .footer-partners {
    	display: inline-block;
    }
    .footer-footer {
    	display: inline-block;
    	width: 100%;
    	max-width: 400px;
    	border-top: 1px solid #424c96;
    	padding-top: 24px;
    	margin-top: 12px;
    	margin-bottom: 33px;
    	span {
    		display: block;
    	}
		// Small, Medium, Large devices
        @media (min-width: @screen-sm-min) {
    		margin-top: 32px;
    		span {
    			display: inline;
    		}
	    	span + span {
	    		&:before, &::before {
	    			content: " | ";
	    			vertical-align: text-bottom;
	    		}
	    	}
        }
    	a {
    		color: @whiteColor;
    	}
    }
}