// zakladni importovaci less 

//
// Packages Styles
//--------------------------------------
// naimportovat jen co je treba a zbytek zakomentovat
// to stejne provest s js v gulpfile.js v path.scripts.src

	// Bootstrap v3.3.6
	//----------------------------------
		// Core variables and mixins
			@import "../../bower_components/bootstrap/less/variables.less";
			@import "../../bower_components/bootstrap/less/mixins.less";

		// Reset and dependencies
			@import "../../bower_components/bootstrap/less/normalize.less";
			@import "../../bower_components/bootstrap/less/print.less";
			@import "../../bower_components/bootstrap/less/glyphicons.less";

		// Core CSS
			@import "../../bower_components/bootstrap/less/scaffolding.less";
			@import "../../bower_components/bootstrap/less/type.less";
			@import "../../bower_components/bootstrap/less/code.less";
			@import "../../bower_components/bootstrap/less/grid.less";
			@import "../../bower_components/bootstrap/less/tables.less";
			@import "../../bower_components/bootstrap/less/forms.less";
			@import "../../bower_components/bootstrap/less/buttons.less";

		// Components
			@import "../../bower_components/bootstrap/less/component-animations.less";
			@import "../../bower_components/bootstrap/less/dropdowns.less";
			@import "../../bower_components/bootstrap/less/button-groups.less";
			@import "../../bower_components/bootstrap/less/input-groups.less";
			@import "../../bower_components/bootstrap/less/navs.less";
			@import "../../bower_components/bootstrap/less/navbar.less";
			@import "../../bower_components/bootstrap/less/breadcrumbs.less";
			@import "../../bower_components/bootstrap/less/pagination.less";
			@import "../../bower_components/bootstrap/less/pager.less";
			@import "../../bower_components/bootstrap/less/labels.less";
			@import "../../bower_components/bootstrap/less/badges.less";
			@import "../../bower_components/bootstrap/less/jumbotron.less";
			@import "../../bower_components/bootstrap/less/thumbnails.less";
			@import "../../bower_components/bootstrap/less/alerts.less";
			@import "../../bower_components/bootstrap/less/progress-bars.less";
			@import "../../bower_components/bootstrap/less/media.less";
			@import "../../bower_components/bootstrap/less/list-group.less";
			@import "../../bower_components/bootstrap/less/panels.less";
			@import "../../bower_components/bootstrap/less/responsive-embed.less";
			@import "../../bower_components/bootstrap/less/wells.less";
			@import "../../bower_components/bootstrap/less/close.less";

		// Components w/ JavaScript
			@import "../../bower_components/bootstrap/less/modals.less";
			@import "../../bower_components/bootstrap/less/tooltip.less";
			@import "../../bower_components/bootstrap/less/popovers.less";
			@import "../../bower_components/bootstrap/less/carousel.less";

		// Utility classes
			@import "../../bower_components/bootstrap/less/utilities.less";
			@import "../../bower_components/bootstrap/less/responsive-utilities.less";

	// Font Awesome
	//----------------------------------
		@import "../../bower_components/font-awesome/less/font-awesome.less";

	// Fancybox
	//----------------------------------
		@import "../preprocess/fancybox-preprocess.css";

	// Owl Carousel
	//----------------------------------
		@import "plugins/owl.carousel/owl.carousel.min.css";	

//
// Document Styles
//--------------------------------------
	// Variables
	//----------------------------------
		@import "variables.less";

	// Basics
	//----------------------------------
		@import "basics.less";

	// BEMs
	//----------------------------------
		// Header
			@import "bems/header.less";

		// Section
			@import "bems/section.less";

		// Footer
			@import "bems/footer.less";

		// Templates
			@import "bems/tmpls.less";