//
// Body, html
//---------------------------------------------------
body, html {
    font-family: "open-sans",sans-serif;
    font-size: 16px;
    color: #010101;
    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        font-size: 12.8px;
    }
}

//
// Typography
//---------------------------------------------------
h1, h2, h3, h4 {
  color: #242c68;
  font-weight: 300;
}

h1 {

}

h2 {
    font-size: 40px;
    &.small {
        font-size: 30px;
    }
    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        font-size: 32px;
        &.small {
            font-size: 24px;
        }
    }
    &.slogan {
        margin: 0;
        color: @whiteColor;
        background-color: #4c94ce;
        font-size: 26px;
        font-weight: 500;
        position: relative;
        padding: 20px 11px 27px 11px;
        text-align: center;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            border-top: 7px solid @themeColor2;
            display: block;
            height: 0;
        }
    }
}

h3 {
    margin-top: 0;
    margin-bottom: 14px;
    font-size: 20px;
    font-weight: 600;
    &.big {
        font-size: 30px;
        font-weight: 300;
    }
    // Small devices
    @media (max-width: @screen-sm-max) { 
        margin-top: 30px;
    }

}

p {
    margin-bottom: 20px;
}

ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    > li {
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: #010101;
        padding-left: 17px;
        line-height: 1.4;
        margin-bottom: 4px;
        &:before, &::before {
            content: "";
            background-image: url(../images/li.png);
            position: absolute;
            top: 4px;
            left: 0;
            width: 8px;
            height: 15px;
            display: block;
            float: left;
        }
        .title {
            font-size: 20px;
            font-weight: 600;
            color: @themeColor1;
            margin-top: -3px;
            margin-bottom: 12px;
            line-height: 1.2;
            // Small devices
            @media (max-width: @screen-sm-max) { 
                margin-top: 20px;
            }
        }
        p {
            padding: 0 17px 15px 17px;
            padding-bottom: 17px;
            margin: 0 0 18px -17px;
            border-bottom: 1px solid #dbdbdb;
            line-height: 1.5;
            // Medium devices
            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                margin-bottom: 14.4px;
            }
        }
        // Small devices
        @media (max-width: @screen-sm-max) { 
            &:last-child p {
                border-bottom: 0;
            }
        }
    }     
}

a {
    &:hover, &:focus, &:active {
        
    }
}

//
// Essential classes
//---------------------------------------------------

.anchor {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: block;
    background-color: @themeColor2;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    z-index: 998;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
    &:after, &:after {
        content: "";
        background-image: url(../images/li.png);
        background-position-x: -24px;
        position: absolute;
        top: 50%;
        margin-top: -5.5px;
        left: 50%;
        margin-left: -10px;
        width: 20px;
        height: 11px;
        display: block;
        float: left;
    }
    &--footer {
        &:after, &:after {
            margin-top: -6.5px;
            background-position-x: -44px;
        }
    }
}

.car {
    // Small devices
    @media (max-width: @screen-sm-max) { 
        margin-top: 25px;
    }
}

.img-responsive {
    display: inline-block;
}

.lists-border-bottom {
    ul {
        padding-bottom: 7px;
        margin: 0 0 15px 0;
        border-bottom: 1px solid #dbdbdb;
    }
    img {
        margin-bottom: 5px;
    }
}

.page-header {
    padding-bottom: 3px;
    border-bottom-color: #dbdbdb;
    margin-bottom: 35px;
}


div[class$="-6"] {
    .page-header {
        margin-bottom: 20px;
    }
    // Small devices
    @media (max-width: @screen-sm-max) { 
        + div[class$="-6"] .page-header {
            margin-top: 50px;
        }
    }
}

.no-padding {
    padding: 0;
}

.no-scroll {
    margin: 0;
    width: 100%;
    height: 100%; 
    position: fixed;
    overflow-y:scroll;
}

.no-scroll-no-sb {
    margin: 0;
    width: 100%;
    height: 100%; 
    overflow-y:hidden;
}

.affix {
    top: 0;
    &, &-top, &-bottom {
    width: 100%;
    z-index: 999;
    }
}

//Extra small devices
@media (max-width: @screen-xs-max) { 
    .display-xs-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-xs-center {
        text-align: center;
    }
}

// Small devices
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) { 
    .display-sm-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-sm-center {
        text-align: center;
    }
    .text-sm-right {
        text-align: right;
    }
    .text-sm-left {
        text-align: left;
    }
}

@media (min-width: @screen-md-min) { 
    .display-md-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .text-md-center {
        text-align: center;
    }
}

.m-0-a {
    margin: 0 auto;
}

.shadow-inset {
    box-shadow:
        inset 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-b {
    box-shadow:
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4);
}

.shadow-inset-t-b {
    box-shadow:
        inset 0px 10px 5px -10px rgba(0, 0, 0, 0.4),
        inset 0px -10px 5px -10px rgba(0, 0, 0, 0.4); 
}

//
// Screen resolutions
//---------------------------------------------------
    // Extra small devices
    @media (max-width: @screen-xs-max) { 
    }

    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
    }

    // Medium devices
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
    }

    // Large devices
    @media (min-width: @screen-lg-min) {
    }

    // Small devices and Extra small devices
    @media (max-width: @screen-sm-max) {
    }

    // Large devices and Medium device
    @media (min-width: @screen-md-min) {
    }

    // Medium devices and Small devices and Extra small devices
    @media (max-width: @screen-md-max) {
    }


//
// Owl carousel fix
//---------------------------------------------------
.owl-carousel {
    z-index: 1;
}

.owl-wrapper-outer {
    z-index: 1;
}

.owl-stage {
    z-index: 1;
}

.owl-stage-outer {
    z-index: 2;
}

.owl-item {
    z-index: 1;
}

//
// Slider
//--
.wrapper-slider {
  position: relative;
-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
margin-bottom: 60px;
}

.owl-nav {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 36px;
    right: 36px;
    overflow: visible;
    margin-top: -25px;
    .owl-prev, .owl-next {
        display: block;
        float: left;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 38px;
        background-color: @themeColor2;
        color: @themeColor1;
        text-align: center;
    }
    .owl-prev {
        padding-right: 5px;
    }
    .owl-next {
        padding-left: 5px;
        float: right;
    }
}

.owl-dots {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
    .owl-dot {
        display: inline-block;
        > span {
            background-color: @whiteColor;
            display: block;
            width: 17px;
            height: 17px;
            -webkit-border-radius: 17px;
            -moz-border-radius: 17px;
            border-radius: 17px;
            margin: 0 8px;
        }
        &.active > span {
            background-color: @themeColor1;
        }
    }
}
// Extra small devices
@media (max-width: @screen-xs-max) {
    .slide {
        padding-top: 0;
    }
}

// Small devices and Extra small devices
@media (max-width: @screen-sm-max) {
    .owl-nav {
        display: none;
    }
    .owl-dots {
        bottom: -40px;
        .owl-dot {
            > span {
                border: 1px solid @themeColor1;
                width: 16px;
                height: 16px;
                -webkit-border-radius: 16px;
                -moz-border-radius: 16px;
                border-radius: 16px;
            }
        }
    }
}


.maps-contact {
    margin-top: 30px;
    margin-bottom: 48px;
}

//
// Contacts
//--

    .contact-item {
        font-size: 14px;
        &__respo {
            margin: 0 auto;
            margin-bottom: 30px;

            // extraSmall devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
                max-width: 550px;
            }
        }
        &__text {
            font-size: 0;
            > ul {
                padding: 0;
                vertical-align: top;
                > li {
                    font-size: 14px;
                    display: block;
                    padding: 0 30px 0px 40px;
                    margin-bottom: 15px;
                    position:relative;
                    min-height:25px;
                    &:before, &::before {
                        background: none;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        top: 5px;
                        font-size: 22px;
                        content: "";
                        position:absolute;
                        left:0;        
                        color: @themeColor1;               
                    }
                    &.map-marker {&:before, &::before{content: "\f041";}}
                    &.earphone {&:before, &::before{content: "\f095";margin-top: -1px;}}
                    &.envelope {&:before, &::before{content: "\f003";margin-top: -6px;}}
                }
            }
        }
        &__footer {
            padding: 30px 10px 0 0;
            border-top: 1px solid #bebebe;       
            margin-bottom: 15px;  
        }
        .form-group {
            margin-bottom: 10px;
        }
        .form-control {
            font-size: 16px;
            height: 49px;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
            &::-webkit-input-placeholder {
               color: #3e3e3e;
            }

            &:-moz-placeholder { /* Firefox 18- */
               color: #3e3e3e;  
            }

            &::-moz-placeholder {  /* Firefox 19+ */
               color: #3e3e3e;  
            }

            &:-ms-input-placeholder {  
               color: #3e3e3e;  
            }
        }
        textarea.form-control {
            height: auto;
        }
        &__btn {
            color: @themeColor1;
            background-color: @themeColor2;
            border-color: transparent;
            font-size: 20px;
            padding: 10.5px 25px;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
            &:hover, &:focus {
                color: @whiteColor;
                background-color: @themeColor1;
                text-decoration: none;
            }

            // Medium devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
                font-size: 20px;
                padding: 9px 18px;
                margin: 0 auto;
            }
        }
    }
