.header {
    
}

.navbar {
	&-style {
		background: rgb(255,255,255);
		background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(224,224,224,1) 100%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(224,224,224,1) 100%);
		background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(224,224,224,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e0e0e0',GradientType=0 );
		-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
		-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
		box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.7);
		border-radius: 0;
		border: 0;
		min-height: 79px;
		margin-bottom: 0;
		ul > li{
			padding-left: 0;
			margin-bottom: 0;
			&:before, &::before {
				content: "";
				display: none;
			}
		}
		.nav > li {
			> a {
				padding: 29.5px 15px 24.5px 15px;
				font-size: 16px;
				color: @themeColor1;
				background-color: transparent;
				border-bottom: 5px solid transparent;
			}
			@media (min-width: (@grid-float-breakpoint-max + 1)) {
				&:hover, &:focus, &.active {
					> a {
						color: @whiteColor;
						background-color: @themeColor1;
						border-bottom-color: @themeColor2;
					}
				}
			}
			@media (max-width: @grid-float-breakpoint-max) {
				&:hover, &:focus {
						> a {
							color: @whiteColor;
							background-color: @themeColor1;
							border-bottom-color: transparent;	
						}
				}
			}
		}
		@media (max-width: @grid-float-breakpoint-max) {
			.dropdown-menu {
				background-color: darken(@themeColor1, 20%) !important;
				border-radius: 0;
				> li > a {
					color: @whiteColor;
					background-color: transparent;
					&:hover, &:focus, &.active {
						background-color: @themeColor2;
					}
				}
				.divider {
					margin: 5px 0;
					background-color: rgba(255, 255, 255, 0.1);
				}
			}
		}
	}
	&-toggle {
		border-color: @themeColor1;
		background-color: @themeColor1;
		margin-top: 22.5px;
		margin-bottom: 22.5px;
		color: @themeColor1;

		.icon-bar {
			background-color: @whiteColor;
		}
	}
	&-brand {
		padding: 16px 0;
		height: 79px;
		// ExtraSmall devices
		@media (max-width: @screen-xs-max) { 
			padding: 0;
			height: auto;
			img {
				float: left;
    			margin-top: 22.5px;
    			margin-bottom: 22.5px;
    			margin-left: 5px;	
    			height: auto;
				max-height: 34px;
			}
		}
	}
}