//
// Variables
// --------------------------------------------------
@articleTitleTextColor:            @blackColor;
@articleButtonBgColor:             @themeColor1;
@articleButtonBgColorHover:        @themeColor2;
@articleButtonTextColor:           @whiteColor;
@articleButtonTextColorHover:      @whiteColor;
@articleButtonBorderColor:         @themeColor2;
@articleButtonBorderColorHover:    @themeColor1;

//
// Essential classes
//---------------------------------------------------
.article-padding {
    padding: 9px;
    @media (min-width: @screen-md-min) {
        padding: 0 9px;
    }
}

.more-btn-wrapper {
    text-align: center;
    margin-top: -10px;
    @media (min-width: @screen-sm-min) {
        margin-top: -74px;
        float: right;
    }
}

.more-btn {
    font-size: 16px;
    color: #242b67;
    position: relative;
    padding-right: 17px;
    text-transform: uppercase;
    &:after, &::after {
        content: "";
        background-image: url(../images/li.png);
        position: absolute;
        top: 3px;
        right: 0;
        width: 8px;
        height: 15px;
        display: block;
        float: right;
    }
}

//
// Articles
//---------------------------------------------------
.article-item {
    &__title {
        color: #242b67;
        padding-bottom: 20px;
        border-bottom: 1px solid #dbdbdb;
        margin-bottom: 20px;
    }
    &__text {
        text-align: center;
        color: #000;
        // Medium devices
        @media (min-width: @screen-md-min) {
            margin-top: 15px;
            text-align: left;
        }
        // Large devices
        @media (min-width: @screen-lg-min) {
            margin-top: 45px;
        }

        p {
            margin-bottom: 20px;
        }
    }
    &__image {
        font-size: 0;
        @media (min-width: @screen-sm-min) {
            picture:nth-child(3n-1) img {
                margin: 0 9px 9px 9px;
            }
        }

        img { 
            margin: 15px auto;
            @media (min-width: @screen-sm-min) {
                display: inline-block;
                margin: 0 auto 20px auto;
            }                
        }

    }
    &__btn {
        color: @themeColor2;
        background-color: @articleButtonBgColor;
        text-transform: uppercase;
        font-size: 16px;
        padding: 13px 38px 12px 14px;
        border-color: transparent;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        position: relative;
        &:after, &::after {
            content: "";
            background-image: url(../images/li.png);
            background-position-x: -8px;
            position: absolute;
            top: 17px;
            right: 18px;
            width: 8px;
            height: 15px;
            display: block;
            float: right;
        }        
        &:hover, &:focus {
            color: @themeColor1;
            background-color: @articleButtonBgColorHover;
            text-decoration: none;
            &:after, &::after {
                background-position-x: 0;
            }
        }

        // Medium devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-sm-max) {
            font-size: 17px;
            padding: 9px 35px 8px 18px;
            margin: 0 auto;
            &:after, &::after {
                top: 14px;
            }
        }
    }
}