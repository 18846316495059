//
// Variables
// --------------------------------------------------
@tileButtonTextColor:               @whiteColor;
@tileButtonBorderColor:             @whiteColor;
@tileButtonTextColorHover:          @whiteColor;
@tileButtonBorderColorHover:        @whiteColor;
@tileTitleTextColor:                @whiteColor;
@tileTitleTextColorHover:           @themeColor1;

//
// Essential classes
//---------------------------------------------------
.tile-padding {
    padding-right: 20px;
    padding-left: 20px;
    // Small devices
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        &:nth-child(odd) { 
            text-align: right;
        }
        &:nth-child(even) { 
            text-align: left;
        }
    }
}

//
// Tiles
//---------------------------------------------------
.tile {
    text-align: center;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-weight: 400;
    overflow: hidden;
    &:hover, &--active {
        .tile__button {
            display: inline-block;
            a {
                opacity: 1;
                -webkit-transition: all 400ms ease-in 300ms;
                -moz-transition: all 400ms ease-in 300ms;
                -ms-transition: all 400ms ease-in 300ms;
                -o-transition: all 400ms ease-in 300ms;
                transition: all 400ms ease-in 300ms; 
            }
        }
        .tile__title {
            bottom: 0;
            height: 260px;
            -webkit-transition: all 300ms ease-in;
            -moz-transition: all 300ms ease-in;
            -ms-transition: all 300ms ease-in;
            -o-transition: all 300ms ease-in;
            transition: all 300ms ease-in;
            // Medium devices
            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                height: 205px;
            }
            // Medium devices
            @media (max-width: 480px) {
                height: 240px;
            }
            div {
                -webkit-transform: translateY(-20%);
                transform: translateY(-20%);
                -webkit-transition: all 300ms ease-in;
                -moz-transition: all 300ms ease-in;
                -ms-transition: all 300ms ease-in;
                -o-transition: all 300ms ease-in;
                transition: all 300ms ease-in;
            }
        }
    }

    &__image {
        img {
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
        }
    }
    &__button {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        a {
            opacity: 0;
            font-size: 20px;
            font-weight: 400;
            color: @tileButtonTextColor;
            border: 2px solid @tileButtonBorderColor;
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px;
            padding: 10.5px 23.5px;
            -webkit-transition: all 100ms ease-out;
            -moz-transition: all 100ms ease-out;
            -ms-transition: all 100ms ease-out;
            -o-transition: all 100ms ease-out;
            transition: all 100ms ease-out; 

            &:hover, &:focus, &:active {
                color: @tileButtonTextColor;
                text-decoration: underline;
            }
        }
    }
    &__title {
        position: absolute;
        bottom: 0;
        display: table;
        width: 100%;
        height: 86px;
        color: @tileTitleTextColor;
        background-color: rgba(76, 148, 206, 0.7);
        border-top: 5px solid @themeColor2;
        padding: 0 10px;
        font-size: 25px;
        line-height: 1.1;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        -webkit-transition: all 300ms ease-out;
        -moz-transition: all 300ms ease-out;
        -ms-transition: all 300ms ease-out;
        -o-transition: all 300ms ease-out;
        transition: all 300ms ease-out;

        div {
            display: table-cell;
            vertical-align: middle;
            padding-top: 0;
            padding-bottom: 0;
            -webkit-transition: all 300ms ease-out;
            -moz-transition: all 300ms ease-out;
            -ms-transition: all 300ms ease-out;
            -o-transition: all 300ms ease-out;
            transition: all 300ms ease-out;
        }
    }     
}