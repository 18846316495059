//
// Variables
// --------------------------------------------------
@gallery-overlayTextColor:       @whiteColor;
@gallery-overlayTextColorHover:  @themeColor2;
@gallery-navColor:               @themeColor1;

//
// Essential classes
//---------------------------------------------------
.gallery-padding {
    padding-right: 20px;
    padding-left: 20px;
    display: inline-block;
}

//
// Slider
//---------------------------------------------------
.gallery {

    border-bottom: 1px solid #dedede;
    margin-bottom: 30px;
    .owl-carousel, .mobile-menu {
        .owl-loading {
            display: block !important;
        }

    }
    .owl-item {
        text-align: center;
    }
    .owl-nav {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: ~"calc(-137px / 2)";
        height: 0;
        overflow: visible;
        display: none;
        background-color: red;

        // ExtraSmall devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
            display: block;
            left: -15px;
            right: -15px;
        }
        // Small devices
        @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
            left: -30px;
            right: -30px;
            display: block;
        }

        // Medium devices
        @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
            left: 0px;
            right: 0px;
            display: block;
        }

        // Large devices
        @media (min-width: @screen-lg-min) {
            left: -40px;
            right: -40px;
            display: block;
        }

        .owl-prev, .owl-next {
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            display: block;
            background-color: @themeColor2;
            text-indent: 100%;
            white-space: nowrap;
            overflow: hidden;
            position: relative;
            z-index: 998;
            top: 30px;
            // Medium devices
            @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                width: 32px;
                height: 32px;
            }
            &:after, &:after {
                content: "";
                background-image: url(../images/li.png);
                position: absolute;
                top: 50%;
                margin-top: -10px;
                left: 50%;
                margin-left: -3.5px;
                width: 11px;
                height: 20px;
                display: block;
                float: left;
                // Medium devices
                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    background-size: 68.8px auto;
                    width: 8px;
                    height: 16px;
                    margin-top: -8px;
                }
            }
        }

        .owl-prev {
            float: left;
            &:after, &::after {
                margin-left: -6.5px;
                background-position-x: -64px;
                // Medium devices
                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    margin-left: -4.5px;
                    background-position-x: -52px;
                }
            }
        }

        .owl-next {
            float: right;
            &:after, &::after {
                background-position-x: -75px;
                margin-left: -2px;
                // Medium devices
                @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
                    background-position-x: -61px;
                }
            }
        }
    }

    // Extra small devices
    @media (max-width: @screen-xs-max) {
        .slide {
            padding-top: 0;
        }
    }
}

//
// Gallery
//---------------------------------------------------
.gallery-pic {
    text-align: center;
    position: relative;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 35px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-weight: 400;
    overflow: hidden;

    &:hover, &--active {
        .gallery-pic__image:after, .gallery-pic__image::after {
            background-color: rgba(0, 0, 0, 0.6);
        }
        .gallery-pic__overlay {               
            top: 55%;
            opacity: 1;
        }
        .gallery-pic__title {
            bottom: -90px;
            -webkit-transition: all 200ms ease-in;
            -moz-transition: all 200ms ease-in;
            -ms-transition: all 200ms ease-in;
            -o-transition: all 200ms ease-in;
            transition: all 200ms ease-in;
        }
    }

    &__image {
        &:after, &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
    &__title {
        position: absolute;
        bottom: 0;
        display: table;
        width: 100%;
        height: 86px;
        color: @tileTitleTextColor;
        background-color: rgba(76, 148, 206, 0.7);
        border-top: 5px solid @themeColor2;
        padding: 0 10px;
        font-size: 25px;
        line-height: 1.1;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        -webkit-transition: all 200ms ease-out;
        -moz-transition: all 200ms ease-out;
        -ms-transition: all 200ms ease-out;
        -o-transition: all 200ms ease-out;
        transition: all 200ms ease-out;
        // Medium devices
        @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
            font-size: 22px;
            padding: 20px 16px;
        }
        div {
            display: table-cell;
            vertical-align: middle;
        }
    }     
    &__overlay {
        display: inline-block;
        position: absolute;
        top: 150%;
        left: 0;
        right: 0;
        transform: translate(0, -50%);
        -webkit-transform: translate(0, -50%);
        -moz-transform: translate(0, -50%);
        -o-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0;
        a {
            font-size: 32px;
            color: @gallery-overlayTextColor;
            padding: 6px;

            // Medium devices
            @media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
                font-size: 44px;
                padding: 12px;
            }

            &:hover {
                color: @gallery-overlayTextColorHover;
                text-decoration: none;
            }
        }
    }
}